/**
 * @constant
 * Nombre del dato que almacena en sessionStorage
 * el token del usuario loqueado.
 */
export const AUTH_USER_TOKEN_KEY = 'ReactAmplify.TokenKey';
/**
 * @constant
 * Nombre del dato que almacena en sessionStorage
 * el grupo al que pertenece el usuario logueado.
 */
export const USER_GROUPS = 'ReactAmplify.Groups';
/**
 * @constant
 * Nombre del dato que almacena en sessionStorage
 * la información completa del usuario logueado.
 */
export const USER_USUARIO = 'USUARIO';
/**
 * @constant
 * Nombre del dato que almacena en sessionStorage
 * el viaje para la Captura Online.
 */
export const VIAJES_CAPTURA = 'VIAJES_CAPTURA';
/**
 * @constant
 * Versión del sistema.
 */
export const VERSION_APP = "Versión: 1.0.0";
/**
 * @constant
 * Nombre de quien desarrolla el sistema.
 */
export const DEV_NAME_APP = "Software Factory";
/**
 * @constant
 * Nombre de la empresa a la cual pertenece el desarrollador
 * del sistema.
 */
export const DEV_CORP_APP = "VWM México";
/**
 * @constant
 * Sitio web del desarrollador.
 */
export const DEV_LINK_APP = "http://portalvwm.vwm.na.vwg/web/software-factory/inicio";
/**
 * @constant
 * Patrón de la contraseña que debe contener:
 * 1. Minímo 8 caracteres.
 * 2. Al menos un número.
 * 3. Al menos una letra minúscula.
 * 4. Al menos una letra mayúscula.
 * 5. Al menos uno de los siguientes carácteres especiales: @ # $ % :
 */
export const PATTERN_CONTRASENIA = "^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%:]).*$";
/**
 * @constant
 * Número de registros que debe mostrar por página el componente datatable.
 */
export const NUM_ROWS_POR_PAGINA = 10;
/**
 * @constant
 * Formato de la fecha que se usa en el sistema.
 */
export const FORMATO_FECHA = "dd/mm/yy";
/**
 * @constant
 * Formato de la fecha y hora que se usa en el sistema
 */
export const FORMATO_FECHA_HORA = 'DD/MM/YYYY HH:mm';
/**
 * @constant
 * Formato de la fecha y Mes que se usa en el sistema
 */
export const FORMATO_FECHA_ANIO_MES = 'MM/YYYY';

/**
 * @constant
 * Rango de años que se utilizan en el componente calendar.
 */
export const RANGO_ANIOS = "2020:2030";
/**
 * @constant
 * Confiración del componente calendar.
 */
export const CALENDAR_LOCALE = {
    firstDayOfWeek: 1,
    dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["D", "L", "M", "MI", "J", "V", "S"],
    monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
    monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
    today: 'Hoy',
    clear: 'Limpiar',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Sm'
};
/**
 * @constant
 * Roles del sistema.
 */
export const ROLES = {
    ADMINUMS: "ADMIN_UMS",
    ADMINSIS: "ADMIN_SISTEMA",
    ADMINREAD: "ADMIN_READ",
    ADMINSIS_LABEL: 'Administrador de sistema',
    ADMINREAD_LABEL: "Administrador de sistema sólo lectura",
    ADMINDEALER: "ADMIN_DEALER",
    ADMINTRANSPORTISTA: "ADMIN_TRANSPORTISTA",
    CONDUCTOR: "CONDUCTOR",
    CONDUCTOR_LABEL: "Conductor",
    INSPECTOR: "INSPECTOR",
    SEGUROS: "SEGUROS",
    SEGUROS_LABEL: "Seguros",
    ADMINNSO: "ADMIN_NSO",
    ADMINNSO_LABEL: "Usuario NSO",
}
/**
 * @constant
 * Estados de cognito.
 */
export const EDOS_COGNITO = {
    NEWPASSWORDREQUIRED: "NEW_PASSWORD_REQUIRED",
    RESET: "RESET",
    CREADO: 'CREADO',
}
/**
 * @constant
 * Empresa VWM México.
 */
export const CODIGO_EMPRESA_VWM = "VW México";
/**
 * @constant
 * Región de los servicios amazon.
 */
export const AWS_REGION = "us-west-2";
/**
 * @constant
 * Url base del api gateway para consumir las apis del sistema.
 */
export const INT_BASE_URL_API = "https://int-api.appslogisticavolkswagen.com.mx";//https://chyuuh5a0a.execute-api.us-west-2.amazonaws.com/int";
export const QAS_BASE_URL_API = "https://qas-api.appslogisticavolkswagen.com.mx"; //"https://5ywm56hz51.execute-api.us-west-2.amazonaws.com/qas";
export const PRD_BASE_URL_API = "https://api.appslogisticavolkswagen.com.mx"; //"https://gcg0bsy254.execute-api.us-west-2.amazonaws.com/prd";
/**
 * Url base del servicio octagon.
 */
export const BASE_URL_OCT = "https://www.o2s.mx/octagon";
/**
 * Configuración del servicio octagon.
 */
export const TOKEN_OCT = "Basic dXNlcjpQMElDREMkMTk=";
export const GRP_TOKEN_OCT =  "GRP";
export const NOMBRE_APP_OCT = "/appdelivery/";
export const AUTH_OCT = "";
export const ORIGINAL_PATH_OCT = "http://int-vwapp-website.s3-website-us-west-2.amazonaws.com/dealerauth";
export const IDCONTROL = "14";
/**
 * @constant
 * Nombre del api que se configura en el archivo aws-exports.js
 * para su uso dentro de la librería api de amplify.
 */
export const INT_API_GATEWAY_NAME = "int-vwapp-api";
export const QAS_API_GATEWAY_NAME = "qas-vwapp-api";
export const PRD_API_GATEWAY_NAME = "prd-vwapp-api";
/**
 * @constant
 * Paths del apu gateway.
 */
export const PATHS_API = {
  USUARIOS: '/usuarios/listar',
  USUARIOS_API: '/usuariosadmin/api',
  VALIDAR_USUARIO_SIGNUP: '/nuevousuario/pre',
  ADMINUMS: '/usuarios/vwm/adminums',
  ADMINSISTEMA: '/usuarios/vwm/adminsistema',
  ADMINSISTEMA_SIGNUP: '/usuarios/api/adminums/usuario/create',
  ADMINDEALER: '/usuarios/concesionaria/admindealer',
  INSPECTOR: '/usuarios/concesionaria/inspector',
  ADMINTRANSPORTISTA: '/usuarios/transportista/admin',
  CONDUCTOR: '/usuarios/transportista/admin',
  CONDUCTOR_SIGNUP: '/usuarios/api/conductor/usuario/create',
  DESTINOS: '/destinos/inicio',
  AGREGAR_INFO_LLEGADA: '/destinos/agregarInfoLlegada',
  VIAJES_CONSULTA: '/destinos/viajes/consultas/drill',
  BITACORA_CONSULTA: '/bitacora/consultas',
  VISITAS_CONSULTA: '/destinos/viajes/consultas/drill/dealer',
  CHASIS_CONSULTA: '/destinos/viajes/consultas/drill/dealer/vins',
  CAT_TRANSPORTISTAS: '/catalogo/transportistas',
  CAT_CONCESIONARIOS: '/catalogo/concesionarios',
  CATALOGO_DANIOS_AREA: '/catalogo/daniosfaltantes/danioArea',
  CATALOGO_DANIOS_TIPO: '/catalogo/daniosfaltantes/danioTipo',
  CATALOGO_DANIOS_SEVERIDAD: '/catalogo/daniosfaltantes/danioSeveridad',
  CATALOGO_FALTANTES: '/catalogo/daniosfaltantes/faltantes',
  CATALOGO_SINIESTROS: '/catalogo/siniestros/lista',
  CATALOGO_RECHAZOS: '/catalogo/motivosrechazo/lista',
  FINALIZAR_VIAJE: '/destinos/finalizar',
  REPORTE_REV: '/destinos/rev',
  REPORTE_FULL_MENSUAL: '/destinos/viajes/reportes',
  OBSERVACIONES_ENTREGA: '/destinos/observaciones',
  BITACORA_CREAR: '/bitacora/crear',
  RESEND_CODE: '/destinos/resend',
  REGEN_CODE: '/destinos/regen',
  USOAPP_CONSULTA: '/bitacora/usoapp',
  CORREOS_CONSULTA: '/destinos/viajes/consultas/correos',
  CORREOS_ACTUALIZA: '/destinos/viajes/consultas/actualizacorreos',
};
/**
 * @constant
 * Rutas del sistema.
 */
export const ROUTES = {
    START: '/',
    LOGIN: '/login',
    LOGIN_CONCESIONARIOS: '/dealerauth',
    OCTAGON_CALLBACK: '/dealerauth/:email/:state',
    SIGNUP: '/signup',
    VERIFYCODE: '/verify-code',
    RESETPASSWORD: '/reset-password',
    FORGOTPASSWORD: '/forgot-password',
    DASHBOARD: '/dashboard',
    USUARIOS: '/usuarios',
    REV: '/rev',
    REV_EMERGENCIA: '/rev/emergencia',
    REV_CAPTURA_ONLINE: '/rev/capturaOnline',
    REV_VIAJE_CAPTURA: '/rev/viajes_Captura',
    VIAJES: '/consultas/viajes',
    BITACORA: '/consultas/bitacora',
    ENCURSO: '/consultas/encurso',
    ENTREGADOS: '/consultas/entregados',
    TRANSPORTISTAS: '/catalogos/transportistas',
    CONCESIONARIOS: '/catalogos/concesionarios',
    USUARIOS_ADMINSIS: '/usuarios/adminsis',
    USUARIOS_ADMINDEALER: '/usuarios/dealer',
    USUARIOS_INSPECTOR: '/usuarios/inspector',
    USUARIOS_ADMINTRANS: '/usuarios/admintrans',
    USUARIOS_CONDUCTOR: '/usuarios/conductor',
    NUEVOCODIGO: '/consultas/nuevocodigo',
    USO_APP: '/consultas/usoapp',
    CORREOS: '/usuarios/correos'
};
/**
 * @constant
 * Constantes para configurar el componente Growl.
 */
export const CONFIG_GROWL  = {
    LIFE: 5000, // en milisegundos
}
/**
 * @constant
 * Constantes que se utilizan en la pantalla Consulta - Viajes.
 */
export const CONSULTA_VIAJES = {
    COD_CONFIRM_VALUE: "COD_CONFIRM",
    NUM_TRK_VALUE: 'NUM_TRK',
    NUM_TRP_VALUE: 'NUM_TRP',
    RANGO_FECHA_VALUE: 'RANGO_FECHAS',
    NUM_ECONO_VALUE: 'NUM_ECONOMICO',
    VIN_VALUE: 'VIN',
    ORDERID_VALUE:'ORDERID',
    MAX_NUMEROS_FILTRO: 10,
    VISTA_VIAJES: 'VIAJES',
    VISTA_VISITAS: 'VISITAS',
    VISTA_CHASIS: 'CHASIS',
    INICIO_VIAJE: 'INICIO_VIAJE',
    ORIGEN:'ORIGEN',
    CONDUCTOR:'CONDUCTOR',
    CARRIER:'CARRIER',
    ESTATUS:'ESTATUS',
    NO_UNIDADES:'NO_UNIDADES',
    FECHA_INICIO:'FECHA_INICIO',
    FECHA_CONSULTA:'FECHA_CONSULTA'
};

/**
 * @constant
 * Constantes que se utilizan en la pantalla Consulta - Bitacora.
 */
export const CONSULTA_BITACORA = {
    RANGO_FECHA_VALUE: 'RANGO_FECHAS',
    MAX_NUMEROS_FILTRO: 10,
    VISTA_BITACORA: 'BITACORA'
};

export const CONSULTA_USOAPP = {
    RANGO_FECHA_VALUE: 'RANGO_FECHAS',
    MAX_NUMEROS_FILTRO: 10,
    VISTA_BITACORA: 'CONSULTA_USOAPP'
};


/**
 * @constant
 * Constantes que se utilizan en la pantalla REV - Captura Online.
 */
export const CAPTURA_ONLINE = {
    PUEBLA: 'Puebla',
    VERACRUZ: 'Veracruz',
    VISTA_VIAJES: 'VIAJES',
    VISTA_VISITAS: 'VISITAS',
    VISTA_CHASIS: 'CHASIS'
};
/**
 * @constant
 * Nombre del dato que almacena en sessionStorage
 * el token del usuario loqueado.
 */
export const ESTADO_ENTREGA_CONFIRMADA = 'ENTREGA_CONFIRMADA';
export const FINALIZADO_CONCESIONARIO = 'FINALIZADO_CONCESIONARIO';
export const FINALIZADO_TRANSPORTISTA = 'FINALIZADO_TRANSPORTISTA';
/**
 * @constant
 * Número de IMAGENES que debe CARAGAR por chasis con danios
 */
export const NUM_IMAGENES_MAX_CHASIS = 3;
/**
 * @constant
 * Milisegundos que deben pasar para volver a activar 
 * la opcion de reset a un usuario
 */
export const MILLIS_RESET_DISABLED = 3600000;
/**
 * @constant
 * Categoria default para un daño
 */
export const DANIO_CATEGORIA = 'CA';
/**
 * @constant
 * Número de IMAGENES que debe CARGAR por concesionario como evidencia llegada
 */
export const NUM_IMAGENES_MAX_EVIDENCIA = 1;
/**
 * @constant
 * Tamaño MAXIMO del archivo que se sube como evidencia en el registro
 * de llegada a concesionaria
 */
export const TAM_MAX_EVIDENCIA = 409600;
/**
 * @constant
 * Número de archivos pdf que debe CARGAR por entrega finalizada
 */
export const NUM_ARCHIVOS_MAX_REV = 1;
/**
 * @constant
 * Link que te lleva a los terminos de servicio
 */
export const LINK_TERMINOS = "https://int-web.appslogisticavolkswagen.com.mx/terminos_appdelivery.html";